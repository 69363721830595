html {
	font-family: "Barlow", sans-serif;
	font-weight: 200;
}

@keyframes scroll {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-100%);
	}
}

.arrow-down {
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 20px solid rgb(186, 230, 253);
}
