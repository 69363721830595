.slide-in {
	animation-name: slide-in;
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-timing-function: ease-in-out;
	opacity: 0;
}

@keyframes slide-in {
	0% {
		opacity: 0;
		transform: translateX(-25px);
		filter: brightness(0.5);
	}
	75% {
		filter: brightness(1.25);
	}
	100% {
		opacity: 1;
		transform: translateX(0px);
		filter: brightness(1);
	}
}

.scale-up {
	animation-name: scale-up;
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-timing-function: none;
}

@keyframes scale-up {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}

.scale-up-less {
	animation-name: scale-up-less;
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-timing-function: none;
}

@keyframes scale-up-less {
	0% {
		transform: scale(0.75);
	}

	100% {
		transform: scale(1);
	}
}

.slide-down {
	animation-name: slide-down;
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	opacity: 0;
}

@keyframes slide-down {
	from {
		opacity: 0;
		margin-top: -25%;
	}
	to {
		opacity: 1;
		margin-top: 0;
	}
}

.basic-slide {
	animation-name: basic-slide;
	animation-duration: 0.75s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-timing-function: ease-in-out;
}

@keyframes basic-slide {
	0% {
		transform: translateX(-200%);
	}
	100% {
		transform: translateX(0px);
	}
}

.appear {
	animation-name: appear;
	animation-duration: 1s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes appear {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
